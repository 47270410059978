<template>
  <BaseTableLoader :loading="loading">
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :headers="tableMixin_displayedHeaders"
      :items="rows"
      :mobile-breakpoint="0"
      disable-pagination
      disable-sort
      hide-default-footer
      @click:row="tableMixin_onRowClick"
    >
      <template v-slot:top>
        <BaseTableHeader
          :title="$t('order_defects')"
          :create-button="$t('create_order_defect')"
          @new-item="$emit('new-item')"
        />
      </template>

      <template v-slot:item.order="{ item }">
        <template v-if="item.order">
          {{ item.order.title }}<br>
          <router-link
            :to="getOrderUrl(item.order)"
            class="link"
          >
            {{ item.order.order_no }}
          </router-link>
        </template>
      </template>

      <template v-slot:item.comment="{ item }">
        <BaseTruncatedField
          :text="item.comment"
          :truncated-length="200"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu
          :actions="actions"
          :item="item"
        />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        />
      </template>
    </v-data-table>
  </BaseTableLoader>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseTableHeader from '../base/BaseTableHeader';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import BaseTableLoader from '../base/BaseTableLoader';
import tableMixin from '../../mixins/table-mixin';
import BaseTruncatedField from '../base/BaseTruncatedField';

export default {
  name: 'OrderDefectTable',

  components: {
    BaseTruncatedField,
    BaseTableLoader,
    BaseExpandedTableRow,
    BaseTableHeader,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: Array,
    pagination: Object,
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ],
      headers: [
        {
          text: this.$t('order'),
          value: 'order',
        },
        {
          text: this.$t('comment'),
          value: 'comment',
          hidden: 'xsOnly',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ],
    };
  },

  methods: {
    getOrderUrl(order) {
      return `/orders?search=${order.order_no}`;
    },
  },
};
</script>
