<template>
  <div class="page-wrapper">
    <OrderDefectTable
      :rows="orderDefectArray"
      :loading="isDataLoading"
      @delete="crudMixin_delete(onDelete, 'orderDefect', $event)"
      @edit="crudMixin_openForm('orderDefect', $event)"
      @new-item="crudMixin_openForm('orderDefect', {})"
    />

    <v-dialog
      v-model="isOrderDefectFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <OrderDefectForm
        :dialog="isOrderDefectFormOpen"
        :form-item="orderDefectFormItem"
        @cancel="isOrderDefectFormOpen = false"
        @create="crudMixin_created('orderDefect', $event)"
        @update="crudMixin_updated('orderDefect', $event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import OrderDefectTable from '../components/tables/OrderDefectTable';
import orderDefectService from '../api/order-defect-service';
import crudMixin from '../mixins/crud-mixin';
import OrderDefectForm from '../components/forms/OrderDefectForm';

export default {
  name: 'OrderDefects',

  components: { OrderDefectForm, OrderDefectTable },

  mixins: [crudMixin],

  data() {
    return {
      orderDefectArray: [],
      orderDefectFormItem: {},
      orderDefectFilterParams: {},
      isOrderDefectFormOpen: false,
      isDataLoading: true,
      onDelete: orderDefectService.delete,
    };
  },

  created() {
    this.getOrderDefects(1);
  },

  methods: {
    getOrderDefects(page) {
      this.crudMixin_getPage(
        orderDefectService.getPage,
        orderDefectService.model,
        page,
        this.orderDefectFilterParams,
      );
    },
  },
};
</script>

<style scoped>

</style>
