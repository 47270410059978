var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableLoader',{attrs:{"loading":_vm.loading}},[_c('v-data-table',{attrs:{"expanded":_vm.tableMixin_expandedRows,"headers":_vm.tableMixin_displayedHeaders,"items":_vm.rows,"mobile-breakpoint":0,"disable-pagination":"","disable-sort":"","hide-default-footer":""},on:{"click:row":_vm.tableMixin_onRowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BaseTableHeader',{attrs:{"title":_vm.$t('order_defects'),"create-button":_vm.$t('create_order_defect')},on:{"new-item":function($event){return _vm.$emit('new-item')}}})]},proxy:true},{key:"item.order",fn:function(ref){
var item = ref.item;
return [(item.order)?[_vm._v(" "+_vm._s(item.order.title)),_c('br'),_c('router-link',{staticClass:"link",attrs:{"to":_vm.getOrderUrl(item.order)}},[_vm._v(" "+_vm._s(item.order.order_no)+" ")])]:_vm._e()]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('BaseTruncatedField',{attrs:{"text":item.comment,"truncated-length":200}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.actions,"item":item}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }